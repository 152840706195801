@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

$page-container-background: rgba(41, 42, 44, 0.752);

$data-table-background: rgba(252, 252, 252, 0.836) !important;
$data-table-title-background: rgba(230, 230, 230, 0.836) !important;
$data-table-border: 1px solid #b8b3b3 !important;
$data-table-text: #3d3d3d;
$data-table-button: rgba(41, 42, 44, 0.752);

$status-negative: #FF4949;
$status-positive: #63da98;
$status-warning: #FFCC3D;
$status-detail: #2d8eff;

//common container
.grid-container{
  height: 100%;
  display: grid;
  grid-template-rows: 6fr;
  grid-template-columns: auto 5fr;
}
.grid-container-maintenance{
  height: 100vh;
  // background: #7ba8bb;
  align-items: center;
  justify-content: center;
  display: flex;
  .page-container-maintenance{
    display: flex;
    margin: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    background: #f5f5f5;
    height: 100%;
    .container{
      .logo{
        height: 2em;
        width: 2em;
      } 
.icon{
  height: 8em;
  width: 8em;
} 
label{
  font-size: 1.5em;
  color: rgb(31, 33, 34);
  font-weight: 600;
  &.title{
  color: #41b1cd;
  font-size: 1em;
padding-bottom: 3em;  }
}
p{
  font-size: 0.9em;
  color: #b8b3b3;
}
}
   
  }
}

.main-container{
  overflow: none !important;
  // background: linear-gradient(180deg, #17E1E1 0%, #F8EFE4 49.48%);
  background: #f5f5f5;
}

.header-container{
  // background-color: $page-container-background;
  box-shadow: none !important;
  padding: 0.5em 0em !important;
  height: 3em;
  .header-closemenu {
    cursor: pointer;
    display: none;
  }
  @media (max-width: 768px){
    .header-closemenu {
      cursor: pointer;
      display: block;
    }
  }
}

.modal{
  justify-content: center;
  align-items: center;
  .modal-dialog{
    &.small-modal{
      justify-content: center;
      display: flex;
      background: #0000003b;
      .modal-content{
        height: 30em;
        width: 25em;
        margin: auto;
        margin-right: auto;
        .modal-body{
          padding: 0.5em !important;
        }
      }
    }
    &.create-modal{
      background: none;
    }
    &.create-modalbox{
      .modal-content{
        height: 90vh;
        width: 100%;
        .modal-body{
          padding: 1em !important;
          .modal-form{
            padding-top: 3em;
            .form-group{
              .icon-row{
                .icon-container{
                  padding: 0;
                  label{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    input{
                      margin-right: 0.5em;
                    }
                  }
                }
              }
              .text-right{
                text-align: end;
                button{
                  width: 10em;
                  box-shadow: none !important;
                }
              }
            }
          }
        }
      }
    }
    .modal-content{
      height: 90vh;
      width: 100%;
      .modal-body{
        padding: 0.5em !important;
      }
    }
  }
}

.modal-form{
  .button-col{
    display: flex;
    align-items: start;
    padding: 0.5em;
    justify-content: center;
  }
}

.login-container{
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 4em;
  .login-form{
    border-radius: 0.5em;
    padding: 4em;
    padding-top: 0;
    padding-bottom: 2em;
    width: 30%;
    min-width: 25em;
    background-color: #F3EEEA;
    .login-label{
      font-family: 'Comfortaa', cursive;
      color: #4b6cb7;
      text-align: center;
      padding-top: 1.5em;
      padding-bottom: 1em;
    }
    .form-outline{
      padding: 0;
    }
    .error-message{
      padding: 0.5em 0em;
      font-family: 'Comfortaa', cursive;
      font-size: small;
    }
    button{
      font-family: 'Comfortaa', cursive;
      text-transform: capitalize;
      background-color: #4b6cb7 !important;
      border-radius: 0.5em;
      padding: 0.5em 1.5em;
      box-shadow: none;
      font-size: medium;
    }
  }
}

.sidebar{
  overflow: hidden;
  position: relative;
  // background: rgba(55, 57, 57, 0.841);
  // background: $page-container-background;
  // background: white !important;
  background: rgba(252, 252, 252, 0.836) !important;
  border-radius: 0.5rem;
  color: #888ea8;
  width: 220px;
  transition: all 0.5s;
  margin: 1em 0em 1em 0.5em;
  &.active{
    .sidebar-container{
      .menu-item{
        padding: 0.5em !important;
      }
    }
  }
  .sidebar-container{
    padding: 0.5em;
    justify-content: center;
    display: grid;
    align-items: center;
    .sidebar-top{
      padding: 1em 0em;
      .sidebar-control{
        justify-content: space-around;
        align-items: center;
        span{
          font-size: large;
        }
        .form-check-input[type=radio]:after{
          background: transparent;
        }
        .form-check-input[type=radio]{
          background: transparent;
          border-color: #888DAA;
          margin: auto !important;
        }
        .form-check-input[type=radio]:checked{
          background: #17E1E1;
          border-color: #17E1E1;
        }
      }
    }
    .side-bar-item{
      overflow: scroll;
      height: 80vh;
      font-size: medium;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      .menu-item{
        margin: 0;   
        padding: 0.5em 0; 
        &.active{
          // background: rgb(54, 54, 59);
          // background: #888ea8;

          border-radius: 0.5rem;
          margin: 0.3em 0;
        }
        &.active:hover{
          // background: #888ea8;

          // background: rgb(54, 54, 59);
        }
        .item-row{
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: start;
        }
        svg{
          // color: whitesmoke;
       color: #888ea8;
        }
        .link_text{
          text-align: center;
          // color: whitesmoke;
          color: #888ea8;
          width: auto;
        }
        .sub-menu{
          // animation: openSubMenu 1s ease-in-out;
          
          padding: 0.5em 0;
          .sub-menu-row{
            padding: 0.3em;
            align-items: center;
            &.active{
              // background: rgb(41, 52, 65);
              background: #f5f5f5;
              // border-radius: 0.5rem;
            }
          }
          .sub-menu-row:hover{
          // background: black;

          }
          .link_text{
            text-align: start;
            font-size: small;
            // color: whitesmoke;
            color: 888ea8;
            width: 15em;
          }
        }

      }
      .menu-item:hover{
        // background: rgb(54, 54, 59);
        border-radius: 0.5rem;
        // transition-delay: 500ms;
      }
    }
    .side-bar-item::-webkit-scrollbar {
      display: none !important;
    }
    .sidebar-bottom{
      position: absolute;
      bottom: 0;
      width: 100%;
      .logout{
        justify-content: space-between;
        padding: 1em;
      }
    }
  }
}



@media (max-width: 768px){
  .grid-container{
  }
  .sidebar{
    // background: rgb(0, 0, 0);
    width: 0em !important;
    margin: 1em 0 !important;
    z-index: 2;
    &.active{
      width: 12em !important;
      margin: 1em 0em 1em 0.5em !important;
      // background: rgb(0, 0, 0);
      .sidebar-top{
        text-align: center;
        .sidebar-control{
          display: block !important;
          .role{
            text-transform: capitalize;
            color: black;
          }
        }
      }
      .link_text{
        font-size: small;
        color: whitesmoke;
        width: auto;
      }
    }
    .sidebar-control{
      display: none !important;
      input{
        display: none;
      }
    }
  }
  .header-container{
    .header-closemenu{
      display: block;
    }
  }
  .p-confirm-popup{
    width: 70%;
    .p-confirm-popup-icon{
      font-size: 1.5em !important;

    }
    .p-confirm-popup-content{
    }
    .p-confirm-popup-footer{
      .p-button-label{
        font-size: 0.7em;
        
      }
    }
  }
  .button-delete{
    margin-left: 1em;
    font-size: 0.8em;
  }
}

.page-container{
  overflow: scroll;
  // background: white;
  background: rgba(252, 252, 252, 0.836);
  border-radius: 0.5rem;
  grid-row: 1;
  grid-column: 2;
  z-index: 0;
  margin: 1em;
  .loader{
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
      width:5em;
      height:5em;
    }
  }
  .qr-btt{
    text-align: end;
  }
  .title{
    font-size: 1.75rem;
  }
}

.action-card-container{
  .tab-cards-colums{
    text-align: center;
    padding: 1em;
    div{
      border-radius: 0.5em;
      background-color: rgba(232, 232, 232, 0.256);
      border: 0.1em solid rgb(27, 25, 25);
      color: rgb(44, 40, 40);
      font-size: large;
      font-weight: 600;
      padding: 2em;
    }
  }
}

.page-container::-webkit-scrollbar {
  display: none !important;
}

.listing-container{
  margin: 1em;
  .listing-table-modal{
    .listing-title{
      color: black;
    }

  }
}

.template-group{
  &.label-listing{
    width: 15em;
    white-space: normal;
  }
}
.p-datatable {
 .p-datatable-header{
  border: none !important;
  border-radius: 0.5em 0.5em 0 0;
  background: $data-table-background;
  .p-toolbar{
    background: transparent !important;
    border: none;
    padding: 0;
    .p-toolbar-group-left{
      .card{
        background-color: #ffffff !important;
        padding: 0rem !important;
        margin: 1em 0.3em;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        transition: width 300ms ease-in, height 300ms ease-in, margin 200ms ease-out;
        &.after {
          height: 1em;
          margin: 0%;
          border-radius: 1em;
          display: none;
           width: auto;
        }
        .title{
          font-weight: 700;
          font-size: 0.7rem;
        }
        .number{
          font-size: 0.9rem;
        }
       
      }
      .p-button{
        &.p-button-success{
          color: #ffffff;
          background: #22C55E;
          border: 0.1em solid #22C55E;
        }
        &.p-button-date{
          color: #ffffff;
          background: #0d9cdf;
          border: 0.1em solid #0d89c2;
        }
        margin-right: 0.5em;
        padding: 0.35em 1em;
        background-color: $data-table-button;
        border: 0.1em solid $data-table-button;
      }
    }
    .p-input-icon-left{
      .pi-search{
&.after{
  margin-top: -2.9em;
}
      }
      .card{
        background-color: #ffffff !important;
        padding: 0rem !important;
        margin: 1em 0.3em;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        transition: width 300ms ease-in, height 300ms ease-in, margin 200ms ease-out;
        &.after {
          height: 1em;
          margin: 0%;
          border-radius: 1em;
          display: none;
           width: auto;
        }
        .title{
          font-weight: 700;
          font-size: 0.7rem;
        }
        .number{
          font-size: 0.9rem;
        }
       
      }
    }
  }
 }
 .p-paginator{
  border: none !important;
  background: $data-table-background;
  .pagination{
    justify-content: end;
    .page-item{
      &.active{
        .page-link{
          padding: 0.4em 1em;
          background-color: $data-table-button;
          color: #e3ebf7;
          border: 0;
          box-shadow: 0 4px 9px -4px #e3ebf7;
          transition: all .2s linear;
          font-weight: 500;
        }
      }
      .page-link{
        align-items: center;
        display: flex;  
        padding: 0.7em 0.5em;
        margin: 0 0.2em;  
      }
    }
  }
 }
 .p-datatable-wrapper{
  border-radius: 0 0 0.5em 0.5em !important;
  .p-datatable-table{
    .p-datatable-thead{
      th{
        color: $data-table-text;
        // border: $data-table-border;
        border: rgba(252, 252, 252, 0.836);;
        background: $data-table-title-background;
      }
    }
    .p-datatable-tbody{
      tr {
        color: $data-table-text;
        background: $data-table-background;
        border: $data-table-border;
        // border: rgba(252, 252, 252, 0.836);

      }
      td{
        padding: 0.3em 1em;
        font-size: medium;
        // border: 1px solid #d3d3d3 !important;
        border: rgba(252, 252, 252, 0.836)!important;

        .p-row-editor-init{
          color: rgb(65, 177, 205);
        }
        .p-inputtext{
          padding: 0.2em;
        }
      }
    } 
  }
 }
}

.info-template{
  .listing-header{
    background: #2e2c2c;
    color: #fff7f7;
    border-radius: 0.5rem;
    padding: 1em 0.5em;
    margin: 0.5em 0;
    .header{
      align-items: center;
      display: flex;
      label{
        margin-right: 1em;
      }
      svg{
        margin-right: 2em;
      }
    }
    .button-icon{
      display: flex;
      align-items: center;
      justify-content: end;
      svg{
        margin-left: 2em;
      }
    }
    .button-delete{
      margin-left: 1em;
      font-size: 0.8em;
    }
  }
  .listing-info{
    &.active{
      padding: 0.5em;
      height: auto;
      label{
        display: block;
      }
      svg{
        display: block;
      }
      .title{
        font-size: large;
        padding-top: 0.5em;
        // padding-bottom: 0.5em;
        margin: 0 !important;
        // text-align: center;
        label{
          width: auto;
          font-weight: bold;
        }
        .edit-button{
          width: 2em;
        }
      }
      .line{
        border-bottom: 0.1em solid rgba(84, 81, 81, 0.364);
        margin: 0.5em 0;
        // padding: 0.5em;
      }
      .listing-content{
        font-size: medium;
        // padding: 1em 0;
        .text{
          margin: 0;
          // text-align: center;
        }
        .date{
          margin: 0;
          padding-top: 0.1em;
          font-size: smaller;
          align-items: end;
          .button-toggle{
            justify-content: end;
            display: flex;
          }
        }
        .line{
          border-bottom: 0.1em solid rgba(84, 81, 81, 0.364);
          margin: 0.5em 0;
          // padding: 0.5em;
        }
        .edit-button{
          width: 2em;
        }
        .price{
          line-height: 1em;
          .original_price{
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            font-size: x-small;
            -webkit-text-decoration-line: line-through; /* Safari */
            text-decoration-line: line-through; 
          }
        }
      }
    }
    background: #e9e5e5;
    border-radius: 0.5rem;
    height: 0;
    margin: 0.5em;
    // transition-duration: 300ms;
    transition: all 0.5s;
    label{
      display: none;
    }
    svg{
      display: none;
    }
  }
}
.p-confirm-popup{
  z-index: 1600 !important;
}

@media (max-width: 768px){
  .listing-info{
    &.active{
      .title{
        font-size: 10px !important;
        .edit-button{
          width: 0.5em !important;
          padding: 0;
        }
      }
      .listing-content{
        font-size: small !important;
        .text{
          .edit-button{
            width: 0.5em !important;
            padding: 0;
            justify-content: center;
            display: flex;
          }
        }
      }
    }
  }
}

@media  (max-width: 780px){
.p-datatable[pr_id_1] .p-datatable-tbody > tr > td > .p-column-title {
    display: block;
    // font-size: 0.8em;
}
}

@media (max-width: 780px){
.p-datatable[pr_id_1] .p-datatable-tbody > tr > td:not(:last-child) {
    border: 0 none;
    font-size: 0.75em;
}
.p-datatable[pr_id_2] .p-datatable-tbody > tr > td:not(:last-child) {
  border: 0 none;
  font-size: 0.75em;
}
.p-datatable[pr_id_3] .p-datatable-tbody > tr > td:not(:last-child) {
  border: 0 none;
  font-size: 0.75em;
}
.p-datatable[pr_id_4] .p-datatable-tbody > tr > td:not(:last-child) {
  border: 0 none;
  font-size: 0.75em;
}
.p-datatable[pr_id_5] .p-datatable-tbody > tr > td:not(:last-child) {
  border: 0 none;
  font-size: 0.75em;
}
.p-datatable[pr_id_6] .p-datatable-tbody > tr > td:not(:last-child) {
  border: 0 none;
  font-size: 0.75em;
}
.p-datatable[pr_id_7] .p-datatable-tbody > tr > td:not(:last-child) {
  border: 0 none;
  font-size: 0.75em;
}
.p-datatable[pr_id_8] .p-datatable-tbody > tr > td:not(:last-child) {
  border: 0 none;
  font-size: 0.75em;
}
.p-datatable[pr_id_9] .p-datatable-tbody > tr > td:not(:last-child) {
border: 0 none;
font-size: 0.75em;
}
.p-datatable[pr_id_10] .p-datatable-tbody > tr > td:not(:last-child) {
border: 0 none;
font-size: 0.75em;
}
.p-datatable[pr_id_11] .p-datatable-tbody > tr > td:not(:last-child) {
border: 0 none;
font-size: 0.75em;
}
.p-datatable[pr_id_12] .p-datatable-tbody > tr > td:not(:last-child) {
border: 0 none;
font-size: 0.75em;
}
.p-datatable[pr_id_13] .p-datatable-tbody > tr > td:not(:last-child) {
border: 0 none;
font-size: 0.75em;
}
.p-datatable[pr_id_14] .p-datatable-tbody > tr > td:not(:last-child) {
border: 0 none;
font-size: 0.75em;
}
.p-datatable[pr_id_15] .p-datatable-tbody > tr > td:not(:last-child) {
  border: 0 none;
  font-size: 0.75em;
  }
  .p-datatable[pr_id_16] .p-datatable-tbody > tr > td:not(:last-child) {
    border: 0 none;
    font-size: 0.75em;
    }
    .p-datatable[pr_id_17] .p-datatable-tbody > tr > td:not(:last-child) {
      border: 0 none;
      font-size: 0.75em;
      }
      .p-datatable[pr_id_18] .p-datatable-tbody > tr > td:not(:last-child) {
        border: 0 none;
        font-size: 0.75em;
        }
        .p-datatable[pr_id_19] .p-datatable-tbody > tr > td:not(:last-child) {
          border: 0 none;
          font-size: 0.75em;
          }
          .p-datatable[pr_id_20] .p-datatable-tbody > tr > td:not(:last-child) {
            border: 0 none;
            font-size: 0.75em;
            }
}

@media (max-width: 768px){
  .p-datatable {
    .p-datatable-header{
     border: none !important;
     border-radius: 0.5em 0.5em 0 0;
     background: $data-table-background;
     .p-toolbar{
       background: transparent !important;
       border: none;
       padding: 0;
       .p-toolbar-group-left{
         .card{
           background-color: #ffffff !important;
           padding: 0rem !important;
           margin: 1em 0.3em;
           display: flex;
           align-items: center;
           text-align: center;
           justify-content: center;
           transition: width 300ms ease-in, height 300ms ease-in, margin 200ms ease-out;
           &.after {
             height: 1em;
             margin: 0%;
             border-radius: 1em;
             display: none;
              width: auto;
           }
           .title{
             font-weight: 700;
             font-size: 0.6rem;
           }
           .number{
            font-size: 0.8rem;

           }
          
         }
         .p-button{
           &.p-button-success{
             color: #ffffff;
             background: #22C55E;
             border: 0.1em solid #22C55E;
           }
           &.p-button-date{
             color: #ffffff;
             background: #0d9cdf;
             border: 0.1em solid #0d89c2;
           }
           margin-right: 0.5em;
           font-size: 0.75em;
           padding: 0.35em 1em;
           background-color: $data-table-button;
           border: 0.1em solid $data-table-button;
         }
       }
       .p-input-icon-left{
         .pi-search{
   &.after{
     margin-top: -2.9em;
   }
         }
         .card{
           background-color: #ffffff !important;
           padding: 0rem !important;
           margin: 1em 0.3em;
           display: flex;
           align-items: center;
           text-align: center;
           justify-content: center;
           transition: width 300ms ease-in, height 300ms ease-in, margin 200ms ease-out;
           &.after {
             height: 1em;
             margin: 0%;
             border-radius: 1em;
             display: none;
              width: auto;
           }
           .title{
             font-weight: 700;
             font-size: 0.6rem;
           }
           .number{
            font-size: 0.8rem;

           }
          
         }
       }
     }
    }
   
   }
}


// common css end //

//modalbox
.booking-modalbox{
  .booking-label-bold{
    font-weight: 550;
    font-size: 1.1rem;
  }
  .booking-package-box{
    margin-top: 0.5em;
    border: 0.15em solid rgba(158, 157, 157, 0.347);
    border-radius: 0.5em;
    padding: 0.5em;
    &.detail{
      max-width: 30em;
      font-size: 1.0.5em;
    }
    .activity{
      margin-top: 0.5em;
    }
    .booking-label-grey{
      color: #888DAA;
    }
  }
  .merchant-status{
    font-style: italic;
    font-weight: lighter;
    font-size: 1em;
    color: white;
    background: rgb(80, 115, 150);
    border-radius: 0.5em;
    padding: 0.2em 1em;
    border: none;
    &.done{
      background: rgb(18, 218, 158);
    }
    &.completed{
      background: rgb(226, 135, 30);
    }
    &.accept{
      background: rgb(230, 69, 48);
    }
  }
  .package-box-container{
    margin-top: 3em;
  }
  .merchant-action-container{
    margin-top: 1em;
    .marchant-action-row{
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }
}

// element //
.status{
  &.active{
    background: $status-positive !important;
    color: #116a32;
    font-size: x-small;
    margin-left: 1em;
    text-transform: capitalize;
    border: 0.1em solid #116a32;
    box-shadow: none !important;
  }  
  &.inactive{
    background: rgb(200, 192, 192) !important;
    color: #5f6160;
    font-size: x-small;
    margin-left: 1em;
    text-transform: capitalize;
    border: 0.1em solid #5f6160;
    box-shadow: none !important;
  }
}

.p-dropdown-panel,
.p-datepicker{
  z-index: 9999 !important;
}

.action-button{
  svg{
    cursor: pointer;
  }
}

.form-group{
  padding: 0.5em 0;
  &.add-button{
    background-color: #4bb771;
    border: none;
    color: rgb(247, 249, 249);
    padding: 0.5em;
    font-size: small;
    border-radius: 0.5em;
  }
  &.remove-button{
    background-color: #e63535;
    border: none;
    color: rgb(255, 255, 255);
    padding: 0.5em 1em;
    font-size: small;
    border-radius: 0.5em;
  }
  .p-togglebutton{
    &.p-highlight{
      padding: 0.5em 2em;
      font-size: small;
      background: $status-positive !important;
      border: none !important;
      color: white !important;
    }
  }
  .p-calendar{
    height: 2.3em;
    width: 100%;
  }
  .rmdp-container{
    width: 100%;
    input{
      height: 2.3em;
      color: #4f4f4f;
      font-size: 1rem;
      padding: 0 1em ;
      width: 100%;
    }
    .rmdp-wrapper{
      width: min-content;
    }
  }
  .package-type-item{
    width: 100%;
    border-radius: 0.5em;
    border: 0.1em solid rgb(33, 41, 53);
    margin: 0.5em;
  }
  &.package-item-button{
    .add-button{
      background: transparent;
      border: 0.1em solid rgb(33, 41, 53);
      color: #2d8eff;
      border-radius: 0.5em;
      // margin-top: 1em;
      width: 100%;
      font-size: small;
      padding: 0.5em 2em;

    }
    .remove-button{
      background: transparent;
      border: 0.1em solid rgb(33, 41, 53);
      color: #ff2d2d;
      height: 3.5em;
      border-radius: 0.5em;
      // margin-top: 1em;
      width: 100%;
      font-size: small;
      padding: 0.5em 2em;

    }
  }
  .image-container{
    position: relative;
    .p-image{
      img{
        border-radius: 0.3em;
      }
    }
    .p-image-preview-indicator:hover{
        border-radius: 0.3em;
    }
    .image-remove-button{
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background: transparent;
      border: none;
      color: white;
    }
  }
  .image-container:hover{
    position: relative;
    width: 9.38em;
    .background{
      border-radius: 0.3em;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: #2e2c2c8d;
    }
    .image-remove-button{
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background: transparent;
      border: none;
      color: white;
    }
  }
  input{
    border: 1px solid #f2f2f2;
    outline: none;
  }
  input::placeholder{
    color: hsl(0, 3%, 78%);
  }
  input:focus{
  }
}
.p-colorpicker-panel{
  z-index: 9999 !important;
}

.booking-body{
  .booking-container{
    border: 0.1em solid  black;
    margin: 0.7em 0em;
    .booking-title{
    color: #000000;

      font-size: 1.1rem;
    }
    .booking-text{
      color: #888DAA;
      font-size: 1rem;
      .btt-check{
        width: auto;
      }
      .btn-primary{
        color: #F5F5F5;
        background: #59D7D7;
        font-size: 0.75em;
      }
    }
  }
  .booking-title{
    color: #000000;
    font-size: 1.2rem;
  }
}

.p-steps{
  .p-steps-current{
    .p-menuitem-link{
      .p-steps-number{
        background: #dc4c64 !important;
        color: #ffffff !important;
      }
    }
  }
}

.p-rating{
  .p-rating-item-active {
    .p-rating-icon{
      color: #FFCB46 !important;
    }
  }
  // .p-rating-item{
  //   color: #FFCB46 !important;

  // }
} 
.custom-badge{
  background: rgb(163, 162, 162);
  padding: 0em 0.5em;
  border-radius: 0.35em;
  font-size: small;
  font-weight: bolder;
  color: rgb(220, 220, 220);
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0.2em solid transparent;

  &.status-W.Payment{

  }
  &.status-Reserved{
    border: 0.2em solid rgb(90, 147, 218);
    background: rgb(110, 196, 227);
    color: rgb(67, 117, 179);
  }
  &.status-Used{
    border: 0.2em solid transparent;
    background: rgb(152, 220, 144);
    color: rgb(56, 145, 51);
  }
  &.status-Expired{
    border: 0.2em solid rgba(207, 205, 205, 0.623);
    background: rgba(198, 198, 198, 0.214);
    color: rgba(185, 182, 182, 0.83);
  }
  &.status-Completed{
    border: 0.2em solid transparent;
    background: rgb(152, 220, 144);
    color: rgb(56, 145, 51);
  }
  &.status-Checked_in{
    border: 0.2em solid rgb(10, 113, 238);
    background: rgb(110, 196, 227);
    color: rgb(67, 117, 179);
  }
  &.status-Checked_out{
    border: 0.2em solid rgb(90, 147, 218);
    background: rgb(238, 104, 14);
    color: rgb(255, 255, 255);
  }
  &.status-Expired{
    border: 0.2em solid rgba(207, 205, 205, 0.623);
    background: rgba(198, 198, 198, 0.214);
    color: rgba(185, 182, 182, 0.83);
  }
  &.status-Inactive{
    border: 0.2em solid transparent;
    background: rgb(145, 38, 38);
    color: rgb(233, 233, 233);
  }
  &.description-Success{
    border: 0.2em solid transparent;
    background: rgb(152, 220, 144);
    color: rgb(56, 145, 51);
  }
}

@keyframes openSubMenu {
  0% {
    height:  0em;
    // opacity: 0.5;
  }
  50% {
    height: 2em;
  }
  100% {
    opacity: 1;
    height: auto;
  }
}

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/password.ttf);
}